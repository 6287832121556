import htmx from "htmx.org";
import { format } from "date-fns-tz";

window.htmx = htmx;

htmx.on("htmx:load", function (evt) {
  const longDates = evt.detail.elt.querySelectorAll("time.timestamp");
  for (const timestamp of longDates) {
    const date = new Date(timestamp.getAttribute("datetime"));
    timestamp.innerHTML = format(date, "yyyy-MM-dd HH:mm:ss zzz");
  }
});
